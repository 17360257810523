import React from 'react'

const OutOfStockForm = ({ closeForm }) => {

  const handleOutsideFormClick = (event) => {
    if (event.target.matches(".order-form")) {
      closeForm()
    }
  }

  return (
    <div className="order-form out-of-scope" onMouseDown={handleOutsideFormClick}>
      <div className="order-form-wrapper">
        <a href="#" alt="close" className="close-icon" onClick={closeForm}></a>
        <h1>ЗАМОВЛЕННЯ</h1>
        <form>
          <hr />
          <p className="out-of-stock-msg">Кошик містить товари, яких наразі немає в наявності. Будь ласка відкорегуйте ваше замовлення.</p>
        </form>
      </div>
    </div>
  )
}

export default OutOfStockForm