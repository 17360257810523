import React from 'react'
import { Link } from 'gatsby'
import Header from './Header'
import Footer from './Footer'
import CartItemCompleted from './CartItemCompleted'

const OrderCompleted = ({order}) => {
    return (
      <div className="cart-box">
        <div className="ordercomplete-box">
        <div>
          <Header />
          <div className="ordercomplete-content container">
            <div className="ordercomplete-header">
              <div className="ordercomplete-header-title">
                Дякуємо за замовлення
              </div>
            </div>
            <div className="ordercomplete-body">
              <div className="ordercomplete-details">
                <div className="order-summary">
                  <div className="ordercomplete-details-header">
                    ЗАМОВЛЕННЯ
                  </div>
                  <div className="ordercomplete-details-item">
                    Номер замовлення : <span>{order.number}</span>
                  </div>
                  <div className="ordercomplete-details-item">
                    Дата замовлення : <span>{order.date}</span>
                  </div>
                  <div className="ordercomplete-details-item">
                    П.І.Б. : <span>{order.name}</span>
                  </div>
                  <div className="ordercomplete-details-item">
                    Email : <span>{order.email}</span>
                  </div>
                  <div className="ordercomplete-details-item">
                    Номер телефону : <span>{order.phone}</span>
                  </div>
                  <div className="ordercomplete-details-item">
                    Тип доставки : <span>{order.deliveryType}</span>
                  </div>
                  <div className="ordercomplete-details-item">
                    Адреса доставки : <span>{order.deliveryAddress}</span>
                  </div>
                  <div className="ordercomplete-details-item">
                    Тип оплати : <span>{order.paymentType}</span>
                  </div>
                  <div className="ordercomplete-details-item">
                    Сума замовлення : <span>{order.total} грн</span>
                  </div>
                </div>
                <div className="order-books">
                  <div className="container-content">
                    <ul className="container-menu">
                      <li className="menu-first-item">Назва товару</li>
                      <li className="menu-item">Ціна одиниці товару</li>
                      <li className="menu-item">Кількість</li>
                      <li className="menu-item">Всього</li>
                    </ul>
                    {order.books.map(book => (
                      <CartItemCompleted key={book.sku} bookitem={book} />
                    ))}
                  </div>
                </div>

              </div>
              <div className="ordercomplete-confirmation">
                <p>
                  Лист підтвердження був відправлений Вам на e-mail :{" "}
                  <span className="highlight">{order.email}</span>
                </p>
                <p className="italic">
                  { order.dontCallBack 
                    ? 'Ми не будемо Вам передзвонювати і приступаємо до найшвидшого опрацювання Вашого замовлення'
                    : 'Найближчим часом ми сконтактуємося з Вами та уточнимо деталі Вашого замовлення'
                  }
                </p>
              </div>
            </div>
            <div className="ordercomplete-footer">
              <Link to="/bookstore">
                <button className="">НАЗАД ДО КНИГАРНІ</button>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      </div>
    )
}

export default OrderCompleted