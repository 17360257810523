import React, { Component } from 'react'
import { graphql } from "gatsby"
import { connect } from 'react-redux'
import { clearCart, alignWithCms } from '../state/cartSlice'
import { clearCategories } from '../state/bookstoreSlice'
import CartEmpty from '../components/CartEmpty'
import OrderCompleted from '../components/OrderCompleted'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CartItem from '../components/CartItem'
import OrderForm from '../components/OrderForm'
import OutOfStockForm from '../components/OutOfStockForm'

class Cart extends Component {
  
  siteData = {
    settings: {},
    icons: {}
  }

  state = {
    showOrderForm: false,
    orderCompleted: false,
    order: {
      name: '',
      email: '',
      phone: '',
      paymentType: '',
      deliveryType: '',
      deliveryAddress: '',
      dontCallBack: false
    }
  }

  saveOrder = (order) => {
    this.setState(state => ({
      showOrderForm: state.showOrderForm,
      orderCompleted: state.orderCompleted,
      order: {...order}
    }))
  }

  calculateOrderTotal = (books) => {
    return books.reduce((totalPrice, book) => {
      return totalPrice += book.price * book.count
    }, 0)
  }

  // submit order by committing new order.md file to github repository
  submitOrder = async (order) => {
    const response = await fetch('/.netlify/functions/submitOrder', {
      method: 'POST',
      body: JSON.stringify({
        order: order,
        site: {
          settings: this.siteData.settings,
          icons: this.siteData.icons
        }
      })
    }).then(response => {
      if (!response.ok) {
        console.log('Error submitting order')
        throw new Error('Error submitting order')
      }
      return response.json()
    })
    return response.orderId
  }

  completeOrder = async (order) => {
    // copy cart details into order
    order.books = [...this.props.cart]

    // calculate order date
    order.date = new Date().toLocaleString(['uk-UA'], {
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit'
    })
    
    // calculate order total price
    order.total = this.calculateOrderTotal(order.books)

    // submit order and get unique order id
    order.number = await this.submitOrder(order)

    // clear cart
    this.props.dispatch(clearCart())

    // clear saved bookstore categories
    this.props.dispatch(clearCategories())
    
    // save state
    this.setState(state => ({
      showOrderForm: false,
      orderCompleted: true,
      order: {...state.cart, ...order}
    }))
  }

  toggleOrderForm = () => {
    this.setState(state => ({
      showOrderForm: !state.showOrderForm,
      order: state.order
    }))
  }

  componentDidMount() {
    // adjusting state of the items in cart with CMS actual data
    // e.g. price, in stock, etc.
    const cmsItems = this.props.data.books.edges.map(item => item.node.frontmatter)
    this.props.dispatch(alignWithCms(cmsItems))
    this.siteData.settings = this.props.data.settings.childConfigYaml
    
    // fetching paths for the static footer icons
    this.props.data.icons.edges.forEach(edge => {
      const pathElements = edge.node.publicURL.split('/')
      const iconId = pathElements[pathElements.length-1].match(/footer_icon_(.*)\.png/)[1]
      this.siteData.icons[iconId] = edge.node.publicURL
    })
  }

  render = () => {
    const { cart } = this.props;

    if (cart.length < 1 && this.state.orderCompleted) {
      return <OrderCompleted order={this.state.order} />
    } else if (cart.length < 1) {
      return <CartEmpty />
    }

    return (
      <div className="cart-box">
        <div className="flex-wrapper">
          <Header />
          <div className="cart-container container">
            <div className="container-title-sidebar">
              <div className="container-title">
                <span>КОШИК</span>
              </div>
            </div>
            <div className="cart-content">
              <div className="container-content">
                <ul className="container-menu">
                  <li className="menu-first-item">Назва товару</li>
                  <li className="menu-item">Ціна одиниці товару</li>
                  <li className="menu-item">Кількість</li>
                  <li className="menu-item">Всього</li>
                  <li className="menu-item">Відмінити</li>
                </ul>
                {cart.map(book => (
                  <CartItem key={book.sku} bookitem={book} />
                ))}
                <div className="order-total-wrapper">
                  <div className="order-total">
                    Всього: <span className="highlight">{this.calculateOrderTotal(cart)}</span> грн
                  </div>
                </div>
                <button className="button-order" onClick={this.toggleOrderForm}>Оформити Замовлення</button>
              </div>
              
            </div>
            { cart.some(book => book.inStock === false)
              ?
              (this.state.showOrderForm ? ( <OutOfStockForm closeForm={this.toggleOrderForm} /> ) : null)
              :
              (this.state.showOrderForm ? ( <OrderForm order={this.state.order} saveOrder={this.saveOrder} completeOrder={this.completeOrder} closeForm={this.toggleOrderForm} /> ) : null)
            }
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export const PageQuery = graphql`
  query PageQuery {
    books: allMarkdownRemark {
      edges {
        node {
          frontmatter {
            sku
            price
            inStock
          }
        }
      }
    }
    settings: file(name: {eq: "site"}) {
      childConfigYaml {
        phoneNumbers
        address
        email
        facebookPage
        instagramPage
      }
    }
    icons: allFile(filter: {extension: {eq: "png"}, name: {regex: "/footer_icon_/"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

export default connect(state => ({ 
  cart: state.cart,
  bookstore: state.bookstore
}))(Cart)