import React from 'react'
import DynamicImage from './DynamicImage'

const CartItemCompleted = ({ bookitem }) => {
  const bookTotal = bookitem.count * bookitem.price
  return (
    <div key={bookitem.sku} className="shopping-book">
      <div className="menu-item-title menu-item-row">
        <div className="img-box">
          <div>
            {<DynamicImage img={bookitem.gallery[0].image} />}
          </div>
        </div>
        <span className="title-text">{bookitem.title}</span>
      </div>
      <div className="menu-item menu-item-row">
        <span>{bookitem.price} грн</span>
      </div>
      <div className="menu-item menu-item-row">
        <span>{bookitem.count}</span>
      </div>
      <div className="menu-item menu-item-row">
        <span>{bookTotal} грн</span>
      </div>
    </div>
  )
}

export default CartItemCompleted