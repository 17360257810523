import { useStaticQuery, graphql } from 'gatsby'

export const useCartSettings = () => {
  const { file } = useStaticQuery(
    graphql`
      query CartSettings {
        file(name: {eq: "cart"}) {
          childConfigYaml {
            paymentTypes
            deliveryTypes
          }
        }
      }
    `
  )
  return file.childConfigYaml
}