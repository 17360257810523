import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useCartSettings } from '../hooks/useCartSettings'

const OrderForm = ({ order, saveOrder, completeOrder, closeForm }) => {
  const { handleSubmit, register, watch, formState: { errors } } = useForm({
    defaultValues: order
  })
  const { paymentTypes, deliveryTypes } = useCartSettings()
  const [orderFormState, setOrderFormState] = useState({
    showLoader: false,
    closeOnOutClick: true
  })

  useEffect(() => {
    const subscription = watch((data) => {
      saveOrder(data)
    })
    return () => {
      subscription.unsubscribe()
    }
  })

  const handleOutsideFormClick = (event) => {
    if (orderFormState.closeOnOutClick && event.target.matches(".order-form")) {
      closeForm()
    }
  }

  const validateEmail = (email) => {
    const pattern = /\S+@\S+\.\S+/
    return pattern.test(email)
  }

  const validatePhone = (phone, minDigits) => {
    const pattern = /^[-+()0-9\s]*$/
    return pattern.test(phone) ? (phone.replace(/\D/g,'').length >= minDigits) : false
  }

  return (
    <div className="order-form" onMouseDown={handleOutsideFormClick}>
      <div className="order-form-wrapper">
        <div className={`loader-wrapper ${orderFormState.showLoader ? 'loader-wrapper-visible' : ''}`}>
          <span className="loader"></span>
        </div>
        <a href="#" alt="close" className="close-icon" onClick={closeForm}></a>
        <h1>ЗАМОВЛЕННЯ</h1>
        <form onSubmit={handleSubmit((data) => {
            setOrderFormState({
              showLoader: true,
              closeOnOutClick: false
            })
            completeOrder(data)
          })}>
          <hr />
          <div>
            <input className={`form-control ${errors.name ? 'form-control-invalid' : ''}`} type="text" placeholder="П.І.Б." 
              {...register("name", { required: true })} >
            </input>
            {errors.name && <p className="input-error">Введіть Ваше П.І.Б.</p>}

            <input className={`form-control ${errors.email ? 'form-control-invalid' : ''}`} type="text" placeholder="Email"
              {...register("email", { required: true, validate: value => validateEmail(value) })} >
            </input>
            {errors.email && <p className="input-error">Введіть коректний Email</p>}
            
            <input className={`form-control ${errors.phone ? 'form-control-invalid' : ''}`} type="text" placeholder="Телефон" 
              {...register("phone", {required: true, minLength: 10, validate: value => validatePhone(value, 10) })} >
            </input>
            {errors.phone && <p className="input-error">Введіть коректний номер телефону</p>}
          </div>

          <div>
            <select className={`form-control ${errors.paymentType ? 'form-control-invalid' : ''}`} required 
              {...register("paymentType", {required: true})} >
              <option value="" disabled hidden>Тип оплати</option>
              { paymentTypes.map(paymentType => <option value={paymentType} key={paymentType}>{paymentType}</option>) }
            </select>
            {errors.paymentType && <p className="input-error">Оберіть тип оплати</p>}
          </div>
          
          <div>
            <select className={`form-control ${errors.deliveryType ? 'form-control-invalid' : ''}`} required 
              {...register("deliveryType", {required: true})} >
              <option value="" disabled hidden>Тип доставки</option>
              { deliveryTypes.map(deliveryType => <option value={deliveryType} key={deliveryType}>{deliveryType}</option>) }
            </select>
            {errors.deliveryType && <p className="input-error">Оберіть тип доставки</p>}
          </div>
          
          <div>
            <textarea className={`form-control ${errors.deliveryAddress ? 'form-control-invalid' : ''}`} placeholder="Адреса доставки"
              {...register("deliveryAddress", {required: true})} >
            </textarea>
            {errors.deliveryAddress && <p className="input-error">Вкажіть адресу доставки</p>}
          </div>
          
          <div className="checkbox-item">
            <label>
              <input type="checkbox" {...register("dontCallBack")}></input>
              <span className="indicator"></span>
              Не передзвонювати мені
            </label>
          </div>
          
          <hr />
          <div className="submit-item">
            <button type="submit" value="submit" formNoValidate>ПІДТВЕРДИТИ</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default OrderForm