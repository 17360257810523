import React from 'react'
import Header from './Header'
import Footer from './Footer'

const CartEmpty = () => {
    return (
        <div className="cart-box">
            <Header />
            <div className="empty-cart">Ваш кошик порожній...</div>
            <Footer />
        </div>
    )
}

export default CartEmpty