import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteFromCart, changeQuantity } from '../state/cartSlice'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import DynamicImage from './DynamicImage'

const CartItem = ({ bookitem }) => {
  const dispatch = useDispatch()
  const bookTotal = bookitem.count * bookitem.price
  return (
    <div key={bookitem.sku} className={`shopping-book ${bookitem.inStock ? '' : 'text-out-of-stock'}`}>
      <div className="menu-item-title menu-item-row">
        <Link className="title-text" to={bookitem.slug}>
          <div className="img-box">
            <div>
              {<DynamicImage img={bookitem.gallery[0].image} />}
            </div>
          </div>
          <span className="title-text">{bookitem.title}</span>
        </Link>
      </div>
      <div className="menu-item menu-item-row">
        <span className="title-text">{bookitem.price} грн</span>
      </div>

      <div className="menu-item menu-item-row menu-item-counter">
        { bookitem.inStock &&
          <StaticImage className="change-quantity-icon menu-item-button" src="../images/cart_item_decrease_count.png" alt="cart_item_decrease_count" 
            onClick={() => dispatch(changeQuantity({ sku: bookitem.sku, count: bookitem.count-1 }))}
          />
        }
        <span className="title-text">{bookitem.count}</span>
        { bookitem.inStock &&
          <StaticImage className="change-quantity-icon menu-item-button" src="../images/cart_item_increase_count.png" alt="cart_item_increase_count" 
            onClick={() => dispatch(changeQuantity({ sku: bookitem.sku, count: bookitem.count+1 }))} 
          />
        }
      </div>
      
      <div className="menu-item menu-item-row">
        <span>
          { bookitem.inStock ? `${bookTotal} грн` : `немає в наявності` }
        </span>
      </div>

      <div className="menu-item menu-item-row">
        <StaticImage className="menu-item-button" src="../images/cart_item_delete.png" alt="cart_item_delete" onClick={() => dispatch(deleteFromCart({ sku: bookitem.sku }))} />
      </div>
    </div>
  )
}

export default CartItem